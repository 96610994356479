import { Component, OnInit , Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-shift-button',
  templateUrl: './shift-button.component.html',
  styleUrls: ['./shift-button.component.scss']
})
export class ShiftButtonComponent implements OnInit {
  @Input() initialShiftState: boolean = false;
  @Output() shiftStateChange = new EventEmitter<boolean>();
  @Output() shiftAvailabilityChange = new EventEmitter<string>();

  buttonText: string;
  isButtonDisabled: boolean = true; 
  isShiftStarted: boolean;
  isShiftAvailable: string = "No upcoming shift";
  

  constructor() { }

  ngOnInit(): void {
    this.isShiftStarted = this.initialShiftState;
    this.buttonText = 'Start Shift';

    // Initial delay for button activation and availability text updates
    setTimeout(() => {
      this.setTimer(5); 
      
      setTimeout(() => {
        this.isShiftAvailable = ""; 
      }, 2000);
    }, 5000);
  }

  
  setTimer(seconds) {
    let countdownInterval = setInterval(() => {
      if (seconds > 0) {
        this.isShiftAvailable = `Shift starts in ${seconds} sec`; 
        this.shiftAvailabilityChange.emit(this.isShiftAvailable); 
        seconds--;
      } else {
        clearInterval(countdownInterval);
        this.isShiftAvailable = "";
        this.shiftAvailabilityChange.emit(this.isShiftAvailable); 
        this.isButtonDisabled = false;
      }
    }, 1000);
  }
  

  // updateShiftAvailability() {
  //   this.isShiftAvailable = this.isShiftStarted 
  //     ? "" // No text if shift is active
  //     : "No upcoming shift"; 
  //   this.shiftAvailabilityChange.emit(this.isShiftAvailable);
  // }

  onClick() {
    if (this.isShiftStarted) {
      this.endShift();
    } else {
      this.startShift();
    }
   
  }

  startShift() {
    this.isShiftStarted = true;
    this.buttonText = 'End Shift';
    this.shiftStateChange.emit(this.isShiftStarted);
    console.log("Shift started!");
  }

  endShift() {
    this.isShiftStarted = false;
    this.buttonText = 'Start Shift';
    this.isShiftAvailable = "No upcoming shift";
    this.shiftAvailabilityChange.emit(this.isShiftAvailable);
    this.shiftStateChange.emit(this.isShiftStarted);
    this.isButtonDisabled = true;
    console.log("Shift ended!");
  }
}