<mat-toolbar class="topbar mat-bg-card">
  <button *ngIf="layoutConf.sidebarStyle !== 'compact'&&!jwtAuth.isDriver()" mat-icon-button id="sidenavToggle"
    (click)="toggleSidenav()">
    <mat-icon>menu</mat-icon>
  </button>

  <div *ngIf="jwtAuth.isDriver()" class="branding" routerLink="/driver/delivery_pickup"
    style="height: 100% !important; position:static !important;width: auto !important;flex-wrap: wrap; line-height: 1;padding:2px;">
    <img [src]="logo" alt="" class="app-logo">
    <p style="font-size: smaller;" class="mm-text-accent">Driver</p>
  </div>


  <div *ngIf=" jwtAuth.isAdmin() || jwtAuth.isPharmacyUser()  || jwtAuth.isBusiness()" class="search-bar">
    <div class="top-search-form">
      <mat-icon *ngIf="!loading; else searchLoader" class="icon-in-bar">search</mat-icon>
      <ng-template #searchLoader>
        <mat-icon class="icon-in-bar icon-in-bar--loader"><mat-spinner diameter="17"></mat-spinner></mat-icon>
      </ng-template>
      <input (focus)="focused = true" (blur)="focused = false" #searchInput [formControl]="searchTermControl"
        [matAutocomplete]="searchOrdersInput" type="text">

      <mat-autocomplete [displayWith]="null" (optionSelected)="selectHandler($event)"
        #searchOrdersInput="matAutocomplete">
        <mat-option *ngFor="let order of searchOrderResults">
          <div [routerLink]="['orders/vieworders/' + order.orderId]" class="search-orders-result">
            <b [ngClass]="{
              'mm-text-green': order.paymentStatus == 1,
              'mm-text-pink': order.paymentStatus != 1
            }" class="mr-2">{{ order.paymentStatus == 1 ? 'P' : 'NP' }}</b>
            <b class="mr-2">${{ order.orderTotal }}</b>
            <span class="mr-2">#{{ order.uniqueOrderId }}</span>
            <span fxFlex="1 1 auto">
              <span>{{ order.firstName }}</span>
              <span *ngIf="order.lastName" class="ml-2">{{ order.lastName }}</span>
            </span>
            <span>{{ order.created_At | date:'ccc MMM yy'}}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
      <div class="no-search-results" *ngIf="shouldDisplayNoResultsMessage()">
        <div class="no-search-results-inner">
          <mat-icon class="no-search-results-icon">search</mat-icon>
          <b>No search results found</b>
          <div>Please try again with a different search type</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="jwtAuth.isDoctor()" fxLayoutAlign="end end" style="width: 100%;" class="mm-mr-3">
    <div class="mm-mr-3">
      <button mat-stroked-button *ngIf="isShiftAvailable" class="mm-text-black">{{isShiftAvailable}}</button>
    </div>

    <app-shift-button [initialShiftState]="isShiftStarted" (shiftStateChange)="onShiftStateChange($event)"
      (shiftAvailabilityChange)="isShiftAvailable = $event">
    </app-shift-button>
  </div>
  <div class="d-flex">
    <button mat-icon-button disableRipple matTooltip="Need Help?"
      [routerLink]="jwtAuth.isDriver()?'/driver/support':'/support'" class="topbar-button-right img-button">
      <mat-icon>help</mat-icon>
    </button>

    <button mat-icon-button disableRipple [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
      <mat-icon>account_circle</mat-icon>
      <div>{{ getUserDisplayName() }}</div>
    </button>
  </div>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="jwtAuth.isDriver()?'/driver/coming-soon':'/coming-soon'">
      <mat-icon>settings</mat-icon>
      <span>Account Settings</span>
    </button>
    <button mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>